<template>
    <svg class="icon" :class="$props.index <= $props.rating ? 'filled' : 'star'"  title="star">
      <use :xlink:href="$props.index <= $props.rating ? '#icon-filledStar' : '#icon-star'" />
    </svg>
</template>

<script>
  export default {
      data: function () {
      },
      props: {
        rating: Number,
        index: Number,
      },
      methods: {
      },
      mounted() {
      }
  }
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';
.filled {
  transform: rotate(180deg);
}
.icon {
  height: 1rem;
  width: 1rem;
  margin: 0 0.5rem 0 0;
}
</style> 