<template>
  <div id="seo" class="props">
    <div class="seo-rating container" v-if="$props.seoRating && $props.seoRatingCount">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-10 seo-rating_container">
          <div class="stars">
            <Star 
              v-for="index in 10" 
              :key="index"
              :index="index"
              :rating="parseInt($props.seoRatingCount)"
            />
          </div> 
          <div class="seo-rating_copy">
            <p>
              <span>{{$props.title}}</span> scored an average of <span>{{$props.seoRatingCount}}</span> out of 10 stars based on <span>{{$props.seoRating}}</span> reviews
            </p>
          </div>  
        </div>
      </div>
    </div>
    <div class="container" v-if="$props.res.top_reviews.length > 0">
      <div class="pad" v-show="$props.res.top_reviews && $props.res.top_reviews.length > 0">
        <reviews
          :reviews="$props.res.top_reviews"
          :title="'Reviews'"
          :sub="'What our customers say about their holidays'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import reviews from "comp_seo/reviews.vue";
import Star from "comp_seo/Star.vue";

export default {
  props: {
    res: Object,
    title: String,
    seoRating: String,
    seoRatingCount: String,
  },
  components: {
    reviews,
    Star,
  },
  methods: {
    addNode(input, form, value) {
      let el = input.cloneNode();
      el.value = this.$props.res[value];
      el.name = "search[" + value + "]";
      form.appendChild(el);
    },
    addHiddenSearchFields() {
      let doc = document;
      let form = doc.querySelector("#searchform"),
        input = doc.createElement("input");

      input.type = "hidden";
      this.addNode(input, form, "within");
      this.addNode(input, form, "near");
    },
  },
  mounted() {
    const app = this;
    let near = app.$props.res.near,
      within = app.$props.res.within;

    if (near !== null && near !== "" && within !== null && within !== "") {
      this.addHiddenSearchFields();
    }
  },
};
</script>

<style scoped lang="scss">
@import "app/assets/stylesheets/_vars.scss";

.props {
  margin-bottom: 2.5rem;
}

.pad {
  padding: 4.375rem 0;
}

.seo-rating {
  background: #fff;
  margin: 2.5rem 0 0 -1.25rem;
  padding: 2.5rem 0;
  width: 100vw;

  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &_copy {
    padding: 0 1.25rem;
    p {
      padding-top: 0.5rem;
    }
    span {
      font-weight: 700;
    }
  }
}

.stars {
  display: flex;
  margin: 0 0 1rem;
}

@media #{$desktop} {
  .seo-rating {
    width: initial;
    margin: 2.5rem 0 0;
    max-width: initial;

    &_container {
      flex-direction: row;
    }

    &_copy {
      padding: 0 1.25rem;
    }
  }

  .stars {
    margin: 0 3rem 0 0;
  }
}
</style>
