/* eslint no-console: 0 */

// import Vue from 'vue'  // Required for debug
import Properties from '../seo.vue'

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector("#seo");
  const data = el.dataset;
  const app = new Vue({
    el,
    render(h){
      return h(Properties, {
        props: {
            res: JSON.parse(data.res),
            title: data.title,
            seoRating: data.ratingvalue,
            seoRatingCount: data.ratingcount,
        }
      });
    }
  });
});
