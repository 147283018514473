<template>
  <section v-if="$props.reviews.length > 0" class="reviews">
    <h2 class="title">{{ $props.title }}</h2>
    <h3 class="sub">{{ $props.sub }}</h3>
    <ul class="list">
      <li v-for="(review, index) in revs">
        <p class="auth">{{ review.author }} - Verified booking</p>
        <p class="rev">{{ review.title }}</p>
        <blockquote :class="{ active: review.show }" class="qu">{{ review.body }}</blockquote>
        <!--<a class="rgt" :href="'/properties/' + review.id">See property ></a>-->
        <p v-if="review.body.length > 195" class="rgt read" @click="showMore($event, index)">
          Read
          <span v-show="!review.show">more</span>
          <span v-show="review.show">less</span>
          >
        </p>
      </li>
    </ul>
  </section>
</template>

<script>
    export default {
        data: function () {
            return {
                revs: this.$props.reviews
            }
        },
        props: {
            reviews: Array,
            title: String,
            sub: String
        },
        methods: {
            showMore(e, index) {
                let rev = this.revs[index],
                    el = e.currentTarget.parentNode;

                rev.show = !(rev.show);

                if (!rev.show) {
                    window.scrollTo({
                        top: el.getBoundingClientRect().top + window.pageYOffset - 250,
                        behavior: "smooth"
                    });
                }
            }
        },
        mounted() {
            const app = this;
            for (let i = 0; i < app.revs.length; i++) {
                Vue.set(app.revs[i], "show", false);
            }
        }
    }
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.sub {
  font-weight: 400;
  margin: 0 0 2.5rem 0;
}

.list {
  list-style: none;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  row-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

@supports (display: grid) {
  .list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: repeat(1, 100%);
  }
}

@media #{$xl-phone} {
  .list {
    -ms-grid-columns: 50%;
    grid-template-columns: repeat(2, calc(50% - .625rem));
  }
}

@media #{$desktop} {
  .title {
    font-size: 1.5rem;
  }

  .list {
    -ms-grid-columns: 33%;
    grid-template-columns: repeat(3, calc(33% - .625rem));
  }

  a {
    position: absolute;
    bottom: .25rem;
    right: 1.25rem;
  }
}

li {
  padding: 0 1.25rem 1.25rem 0;
  box-sizing: border-box;
  position: relative;
}

.qu {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 7rem;
  line-height: 1.4;
  transition: max-height .25s ease;

  &.active {
    overflow: inherit;
    display: block;
    max-height: 100rem;
  }

  &:before,
  &:after {
    content: '"';
  }
}

.rgt {
  text-align: right;
}

.auth {
  font-weight: 700;
  padding: 0 0 .313rem 0;
}

.rev {
  font-weight: 400;
}

.auth, .rev, .read {
  font-size: .875rem;
}

.rev,
.qu {
  margin: 0 0 .625rem 0;
}

.read {
  cursor: pointer;
}

.qu,
a {
  font-size: .875rem;
}

a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: right;
  color: $col_primary;

  &:hover {
    text-decoration: underline;
  }
}

</style>